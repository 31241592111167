import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import ContactButton from './ContactButton';

const Header = styled(Flex)`
  min-width: 300px;
  height: 30px;
  border-radius: 25px;
  background-color: #f0862e;
  box-sizing: border-box;
`;

const DepartmentSchedule = ({ list = [], requiredReservation, size = 'lg' }) => (
  <Flex
    column
    css={`
      margin-bottom: 30px;
    `}
  >
    <Header>
      <Flex center>
        <FontAwesomeIcon
          icon={faClock}
          style={{ fontSize: 20, marginLeft: 20, marginRight: 10 }}
          color="#fff"
        />
      </Flex>
      <Flex center>
        <span
          css={`
            color: #fff;
          `}
        >
          診療時間
        </span>
      </Flex>
    </Header>
    <Flex
      column
      center
      css={`
        margin-top: 10px;
      `}
    >
      {requiredReservation && (
        <Flex>
          <div
            css={`
              font-size: 18px;
              color: #d30000;
              margin-bottom: 10px;
            `}
          >
            完全予約制
          </div>
        </Flex>
      )}
      {list.map(({ day, time, time2 }) => (
        <Flex
          key={day}
          css={`
            margin-bottom: 10px;
          `}
        >
          <div
            css={`
              margin-right: 20px;
            `}
          >
            {day}
          </div>
          {time2 ? (
            <Flex column>
              <div>{time}</div>
              <div>{time2}</div>
            </Flex>
          ) : (
            <div>{time}</div>
          )}
        </Flex>
      ))}
      {requiredReservation && (
        <div
          css={`
            width: 100%;
            margin: 5px 0;
          `}
        >
          <ContactButton label="予約する" buttonColor="#f0862e" fullWidth={true} />
        </div>
      )}
      {requiredReservation && size === 'sm' && (
        <div
          css={`
            width: 100%;
            margin: 5px 0;
          `}
        >
          <ContactButton tel="tel:0334003288" label="電話でお問い合わせ" fullWidth={true} />
        </div>
      )}
    </Flex>
  </Flex>
);

export default DepartmentSchedule;
