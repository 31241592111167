import React from 'react';
import { graphql } from 'gatsby';
import Flex, { FlexItem } from 'styled-flex-component';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import { P } from '../components/common/P.style';
import { Img } from '../components/common/Img.style';
import Link from '../components/Link';
import { H2, H2Background, H3, H4 } from '../components/common/H.style';
import { UL } from '../components/common/UL.style'; //平畑追加
import { LI } from '../components/common/LI.style'; //平畑追加
import DepartmentSchedule from '../components/DepartmentSchedule';
import DoctorInCharge from '../components/DoctorInCharge';
import SymptomExamples from '../components/SymptomExamples';
import res1lg from '../assets/img/res-1-lg.png';

const RespiratoryArticleList = ({ list = [] }) => (
  <Flex column>
    {list.map(({ id, date, title, path }, index) => (
      <Flex
        key={id}
        css={`
          height: 60px;
          margin: 5px 0;
          border-bottom: ${(list.length - 1 !== index && 'solid 1px #ccc') || 'inherit'};
        `}
      >
        <Flex center>
          <span
            css={`
              font-size: 12px;
              color: #666666;
              margin-right: 30px;
            `}
          >
            {date}
          </span>
        </Flex>
        <Flex center>
          <Link to={path}>
            <span
              css={`
                font-size: 16px;
                color: #3b4043;
                overflow-wrap: anywhere;
              `}
            >
              {title}
            </span>
          </Link>
        </Flex>
      </Flex>
    ))}
  </Flex>
);

const Respiratory = ({ data: { allMarkdownRemark, allFile } }) => {
  const { size } = useTrackedState();
  const respiratoryArticles = allMarkdownRemark.edges
    .filter(
      edge => !!edge.node.frontmatter.date
    )
    .map(({ node: { id, frontmatter: { title, path } } }) => ({
      id,
      title,
      path,
    }));
  return (
    <SubLayout topTitle="呼吸器内科">
      <Img src={res1lg} />
      <Flex
        wrap="true"
        justifyAround
        css={`
        margin-bottom: 30px;
      `}
      >
        <FlexItem
          css={`
          max-width: 600px;
        `}
        >
          呼吸器内科では、主に気管支・肺・胸膜の病気を扱います。<br />
        放置していると危険な病気も多く、心配な症状がある際は、早めに受診を。<br />
          <a href="https://telemed-app.hirahata-clinic.or.jp/" target="_blank">ヒラハタクリニックのオンライン診療</a>もお気軽にご利用ください。

        <H3>取扱う主な疾患</H3>
          <UL>
            <LI>気管支喘息</LI>
            <LI>COPD（肺気腫、慢性気管支炎）</LI>
            <LI>呼吸器感染症（細菌性肺炎・インフルエンザ・非結核性抗酸菌・真菌など）</LI>
            <LI>気胸</LI>
            <LI>気管支拡張症</LI>
            <LI>睡眠時無呼吸症候群（簡易検査/CPAP管理）</LI>
            <LI>禁煙治療</LI>
            <LI>呼吸不全（在宅酸素管理）</LI>
            <LI>間質性肺炎</LI>
            <LI>悪性腫瘍（肺癌・胸膜癌・縦隔腫瘍など　定期検査/終末期管理）</LI>
            <LI>職業関連肺疾患（石綿肺定期健診）</LI>
          </UL>
        </FlexItem>
        <SymptomExamples
          list={[
            '長引く咳',
            'ゼーゼー・ヒューヒュ－音がする',
            '痰がよくでる',
            '動いた時に息切れする',
            '息苦しい',
            '夜間のいびき',
            '禁煙できない',
            '風邪が長引く',
            '健診のレントゲンで異常が出た',
            '息をすると痛む',
          ]}
        />
      </Flex>
      <H3>当院で可能な検査</H3>
      <UL>
        <LI>X線検査（レントゲン）</LI>
        <LI>呼吸機能検査</LI>
        <LI>喀痰検査</LI>
        <LI>採血（院内迅速検査・外注）</LI>
        <LI>SpO2測定</LI>
        <LI>簡易PSG（睡眠時無呼吸症候群の検査）</LI>
        <LI>胸部CTは外部医療機関に依頼し、当院で結果説明可能</LI>
      </UL>

      <H3>よくある症状</H3>

        ここでは、よくある症状とその対処についてご紹介いたします。

      <H4>咳（咳嗽）</H4>
      <P>
        咳嗽は呼吸器疾患で最も多い症状の一つです。<br />
      </P>
      <UL>
        <LI>３週未満： 急性咳嗽</LI>
        <LI>３～８週未満： 遷延性咳嗽</LI>
        <LI>８週以上： 慢性咳嗽</LI>
      </UL>
      <P>
        と分類し、期間により疾患がある程度推定されます。<br />
          急性咳嗽の多くは感染症(ウィルス性の風邪)が原因です。<br />
        <b>遷延性・慢性咳嗽の原因は、咳喘息・逆流性食道炎・後鼻漏・アトピー咳嗽・感染後咳嗽が多い</b>とされています。<br />
          採血・喀痰検査、画像検査、呼吸機能検査にて咳嗽の原因検索を行い、治療します。
        </P>

      <H4>気管支喘息</H4>
      <P>
        気管支（空気の通り道）に炎症が続き、気道が過敏となる状態です。
        様々な刺激により発作的に気道が狭くなり、息を吐くときにゼーゼー・ヒューヒュー音がして息苦しくなります（喘息発作）。
          <b>長期的に治療を行わないと頻回に発作が起こり、次第に気道狭窄が戻らなくなります。</b>
          また、多くの患者さんにアレルギー体質を認めます。<br />
          外来では、アレルギー検査、画像検査、呼吸異能検査にて喘息の診断および治療管理を行います。
        </P>

      <H4>動いた時の息切れ、息苦しさ</H4>
      <P>
        動いた時の息切れ（慢性呼吸不全）の主な原因として、
        </P>
      <UL>
        <LI>COPD（肺気腫・慢性気管支炎）</LI>
        <LI>肺結核後遺症</LI>
        <LI>間質性肺炎</LI>
        <LI>肺がん</LI>
      </UL>
      <P>
        などがあります。
        SpO2（血液中の酸素濃度の指標）が90%未満で持続する場合、在宅酸素療法が必要となります。
          <b>当院では在宅酸素療法指導・管理が可能</b>です。
        </P>

      <H4>夜間のいびき</H4>
      <P>
        夜間のいびき・無呼吸、日中の過度な眠気は睡眠時無呼吸症候群の可能性があります。
          <b>睡眠時無呼吸症候群は</b>
      </P>
      <UL>
        <LI><b>高血圧</b></LI>
        <LI><b>糖尿病</b></LI>
        <LI><b>心筋梗塞</b></LI>
        <LI><b>脳卒中</b></LI>
        <LI><b>突然死</b></LI>
      </UL>
      <P>
        <b>などの合併症を起こしやすくなります。</b>
          当院では自宅で簡単にできる検査（簡易PSG）でチェックができ、もし睡眠時無呼吸症候群であれば、重症度に応じて
        </P>
      <UL>
        <LI>歯科へのマウスピース作成依頼（紹介状作成）</LI>
        <LI>専用の機械（CPAP）の貸し出し・長期管理</LI>
        <LI>必要があれば他院への紹介状作成</LI>
      </UL>
      <P>
        等を行います。
        </P>

      <H4>禁煙</H4>
      <P>
        日本人では喫煙により約8-10年寿命が短縮するとされています。
        肺癌のリスクは4-7倍まで上がるとされ、その他虚血性心疾患、脳卒中、COPD、糖尿病の発症リスクも上がります。
          逆に、<b>禁煙するだけで、多くの疾患を予防することができます。
          当院の禁煙外来も、お気軽にご利用ください。</b>
      </P>

      <H3>呼吸器内科のその他の記事</H3>
      <RespiratoryArticleList list={respiratoryArticles} />
    </SubLayout>
  );
};

export default Respiratory;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/respiratory-articles/" } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 500)
          frontmatter {
            date(formatString: "YYYY/MM/DD")
            title
            path
          }
        }
      }
    }
  }
`;
