import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd } from '@fortawesome/free-solid-svg-icons';

const Header = styled(Flex)`
  min-width: 300px;
  height: 30px;
  border-radius: 25px;
  background-color: #f0862e;
  box-sizing: border-box;
`;

const DoctorInCharge = ({ lines = [] }) => (
  <Flex
    column
    css={`
      margin-bottom: 30px;
    `}
  >
    <Header>
      <Flex center>
        <FontAwesomeIcon
          icon={faUserMd}
          style={{ fontSize: 20, marginLeft: 20, marginRight: 10 }}
          color="#fff"
        />
      </Flex>
      <Flex center>
        <span
          css={`
            color: #fff;
          `}
        >
          担当医
        </span>
      </Flex>
    </Header>
    <Flex
      column
      center
      css={`
        margin-top: 10px;
      `}
    >
      {lines.map(line => (
        <div
          key={line}
          css={`
            margin-bottom: 10px;
          `}
        >
          {line}
        </div>
      ))}
    </Flex>
  </Flex>
);

export default DoctorInCharge;
